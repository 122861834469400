.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease;
  }
  
  .contact-form-container.success {
    opacity: 0.7; /* Slightly fade out */
    animation: scaleUp 0.5s ease forwards;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1); /* Slightly enlarge */
    }
  }
  
  .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: green;
    font-weight: bold;
    text-align: center;
  }
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10px auto;
    box-shadow: inset 0px 0px 0px #61dafb;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  }
  
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #61dafb;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #61dafb;
    }
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  
  .contact-form label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    color: #282c34;
    box-sizing: border-box; 
  }
  
  .contact-form textarea {
    min-height: 150px; 
    resize: vertical; 
    max-width: 100%;   
  }
  
  .contact-form button {
    background-color: #61dafb;
    color: #282c34;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #21a1f1;
  }