/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: rgb(47, 68, 106);
  color: #fff;
}

#projects {
  padding: 80px 20px;
  background-color: #282c34;
  text-align: center;
}

.projects-content {
  max-width: 1200px;
  margin: 0 auto;
}

.projects-content h1 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  color: #61dafb;
}

.categories {
  margin-bottom: 30px;
}

.categories button {
  background: none;
  border: 1px solid #61dafb;
  color: #61dafb;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.categories button.active,
.categories button:hover {
  background-color: #61dafb;
  color: #282c34;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.project-box {
  background-color: #1c1f25;
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
  min-height: 500px;
  color: #fff;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.project-box:hover {
  transform: translateY(-5px);
}

.project-image-video,
.project-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.project-box h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.project-box p {
  margin-bottom: 10px;
  font-size: 1rem;
}

.project-box a {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-box a:hover {
  color: #21a1f1;
}

.resume-button {
  margin-top: 40px;
}

.resume-button a {
  background-color: #61dafb;
  color: #282c34;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.resume-button a:hover {
  background-color: rgba(97, 218, 251, 0.8);
}
