html {
    scroll-behavior: smooth;
}


body {
    background-color: #282c34; /* Or any global background color you prefer */
    color: white;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  section {
    padding: 50px 20px;
  }

.cta-button {
  background-color: #61dafb;
  color: #282c34;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #21a1f1;
  transform: translateY(-3px);
}

.cta-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 161, 241, 0.5);
}

.cta-button-dark {
  background-color: white;
  color: #282c34;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button-dark:hover {
  background-color: #61dafb;
  transform: translateY(-3px);
}

.cta-button-dark:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 161, 241, 0.5);
}
